import AspectRatio from '@components/aspectRatio';
import { BannerComponentInterface, HomeFeedResultInterface } from '@customTypes/index';
import { useDeviceInfo } from '@hooks/react-use';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
type Iprops = HomeFeedResultInterface;
const getBannerUrl = (deepLink: string) => {
  const res = deepLink.split('web_url=');
  return res.length === 2 ? res[1] : '/';
};
const HomePageBannerSection = ({
  result,
  v_position
}: {
  result: Iprops;
  v_position: number;
}) => {
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const {
    data
  } = (result.content[0].component as BannerComponentInterface);
  const bannerData = !screen_isDesktop ? data?.thumbnail_mobile : data?.thumbnail;
  const linkTarget = bannerData?.open_new_tab ? '_blank' : '_self';
  return _jsxs(_Fragment, {
    children: [_jsx(AspectRatio, {
      ratio: screen_isDesktop ? 8 : 4,
      children: _jsx(Link, {
        href: getBannerUrl(bannerData.click_action.deep_link ?? ''),
        prefetch: false,
        passHref: true,
        legacyBehavior: true,
        children: _jsx("a", {
          target: linkTarget,
          children: _jsx(Image, {
            src: bannerData?.data,
            alt: "banner",
            objectFit: "fill",
            layout: "fill",
            unoptimized: true
          })
        })
      })
    }), v_position % 4 === 0 && _jsx("div", {
      id: `ins_banner_container_b${`${v_position + 5}`}`
    })]
  });
};
export default HomePageBannerSection;