import { Section } from '@components/Section';
import VideoCard from '@components/videoCard';
import { HomeFeedResultInterface, VideoComponentInterface } from '@customTypes/index';
import { useMemo } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export enum SkyEsportsTournamentTitleEnum {
  LIVE = 'Live',
  HIGHLIGHTS = 'Tournament Highlights',
  CLIPS = 'Clips',
  RECAP = 'Recap',
  GEARING_UP_FOR_FINALS = 'Gearing Up for Finals',
}
const substringCheckInTitle = (str: string, sub_str: string) => {
  return str.toLowerCase().includes(sub_str.toLowerCase());
};
export const SkyEsportsVideosSection = ({
  result,
  v_position,
  maxCount,
  hideShowMore = false
}: {
  result: HomeFeedResultInterface;
  v_position: number;
  maxCount: number;
  hideShowMore?: boolean;
}) => {
  //Live Stream won't have a see more button and all the content will be shown in multiple rows
  const isLiveStreamsSection = substringCheckInTitle(result?.title, SkyEsportsTournamentTitleEnum.LIVE);

  //To hide see more button when content is less that or equal to 4(maxCount) videos
  const isContentLessThanMaxCount = result.content.length <= maxCount;

  //Max Videos to show for each section. Live stream will show all. 0 value means to show all vidoes
  const maxCountToShow = maxCount && !isLiveStreamsSection ? maxCount : result.content.length;
  const handleRoute = () => {
    if (substringCheckInTitle(result?.title, SkyEsportsTournamentTitleEnum.HIGHLIGHTS)) {
      return '/events/bgmi-skyesports-championship/tournament-highlights';
    } else if (substringCheckInTitle(result?.title, SkyEsportsTournamentTitleEnum.RECAP)) {
      return '/events/bgmi-skyesports-championship/tournament-streams';
    } else if (substringCheckInTitle(result?.title, SkyEsportsTournamentTitleEnum.GEARING_UP_FOR_FINALS)) {
      return '/events/bgmi-skyesports-championship/tournament-videos';
    }
  };
  const VideosComponent = useMemo(() => {
    return result.content.slice(0, maxCountToShow).map((video, index) => {
      const {
        data,
        analytics
      } = (video.component as VideoComponentInterface);
      return _jsx("div", {
        className: `${hideShowMore || isLiveStreamsSection ? 'w-auto' : 'w-[60vw]'} md:w-auto`,
        children: _jsx(VideoCard, {
          src: data?.playback_url?.playback,
          stream_id: data?.stream_uid?.data,
          stream_title: data?.stream_title?.data,
          is_live: data?.is_live,
          streamer_avatar_src: data?.streamer_image?.data,
          streamer_id: data?.streamer_uid?.data,
          streamer_name: data?.streamer_name?.data,
          thumbnail_src: data?.thumbnail?.data,
          gametitle: data?.stream_tags[0]?.data,
          gameUID: analytics.category_id,
          views: data?.view_count?.data,
          uploaded_time: data?.uploaded_time?.data,
          duration: data?.duration?.data,
          source_component_data: {
            source_component_name: result?.title ?? '',
            source_component_type: 'video grid',
            source_component_v_position: v_position,
            source_component_h_position: 1,
            source_component_size: maxCountToShow,
            source_position_in_source_component: index + 1,
            source_view_all_in_source_component: 'no',
            source_ingredient_name: result?.title,
            source_ingredient_position: v_position,
            source_ingredient_size: result?.content?.length,
            source_position_in_ingredient: index + 1
          }
        })
      }, data?.stream_uid?.data);
    });
  }, [maxCountToShow, result.content]);
  if (substringCheckInTitle(result?.title, SkyEsportsTournamentTitleEnum.CLIPS)) return null;
  return _jsx("div", {
    className: "px-4 md:px-0",
    children: _jsx(Section, {
      title: result.title,
      subTitle: "See More",
      subTitleLink: hideShowMore || isLiveStreamsSection || isContentLessThanMaxCount ? '' : handleRoute(),
      sectionBeforeHighlighted: result.title //To avoid border bottom css property
      ,
      source_component_data: {
        source_component_name: result?.title ?? '',
        source_component_type: 'video grid',
        source_component_v_position: v_position,
        source_component_h_position: 1,
        source_component_size: maxCountToShow,
        source_view_all_in_source_component: 'yes',
        source_ingredient_name: result?.title,
        source_ingredient_position: v_position,
        source_ingredient_size: result?.content?.length
      },
      children: _jsx("div", {
        id: "skyesports-video-section-scroll",
        className: hideShowMore || isLiveStreamsSection ? 'grid grid-gap-2 grid-cols-2 md:grid-cols-4' : 'flex overflow-x-scroll md:overflow-x-hidden mb-6 md:mb-0 md:grid gap-2 overflow-hidden md:grid-cols-4',
        children: VideosComponent
      })
    })
  });
};