function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Loader from '@components/loader';
import { FEEDCOMPONENTTYPE, HomeFeedResultInterface } from '@customTypes/index';
import { useDeviceInfo } from '@hooks/react-use';
import useInfiniteScroll from '@hooks/useInfiniteScroll';
import { getBannerAdspotKey, insertBannerINSTag, placementIds } from '@modules/ads/setUpBannerAds';
import { useAdsStore } from '@stores/adsStore';
import { useAppStore } from '@stores/appStore';
import React, { useEffect, useState } from 'react';
import HomePageBannerSection from './homePageBannerSection';
import HomePageCategorySection from './homePageCategoriesSection';
import HomePageStreamersSection from './homePageStreamersSection';
import HomePageVideosSection from './homePageVideosSection';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const INITIAL_ITEMS = 3;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "zl1inp",
  styles: "display:flex;justify-content:center"
} : {
  name: "p43uzo-HomeFeedComponent",
  styles: "display:flex;justify-content:center;label:HomeFeedComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const HomeFeedComponent = ({
  homeFeed: FEED_DATA,
  sectionBeforeHighlighted = ''
}: {
  homeFeed: HomeFeedResultInterface[];
  sectionBeforeHighlighted: string;
}) => {
  const [homeFeedData, setHomeFeed] = useState<HomeFeedResultInterface[]>(FEED_DATA.slice(0, INITIAL_ITEMS));
  const [loading, setLoading] = useState(false);
  const [hasNext, setHasNext] = useState(FEED_DATA.length > INITIAL_ITEMS);
  const adsScriptLoaded = useAppStore(state => state.adsScriptLoaded);
  const {
    device_isMobile,
    screen_isMobile
  } = useDeviceInfo();
  const isMobile = () => device_isMobile || screen_isMobile;
  useEffect(() => {
    setHasNext(FEED_DATA.length > homeFeedData.length);
  }, [FEED_DATA, homeFeedData]);
  const handleLoadMore = () => {
    if (hasNext) {
      setLoading(true);
      setHomeFeed(item => {
        const nextChunk = FEED_DATA.slice(item.length, item.length + 2);
        return [...item, ...nextChunk];
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (adsScriptLoaded) {
      if (isMobile()) {
        insertBannerINSTag(getBannerAdspotKey().HOME_PAGE_MOBILE);
      } else {
        insertBannerINSTag(getBannerAdspotKey().HOME_PAGE_DESKTOP);
      }
      useAdsStore.getState().setBannerAdType('banner');
    }
  }, [adsScriptLoaded, placementIds.length, homeFeedData.length]);
  const {
    ref: moreRef
  } = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNext,
    onLoadMore: handleLoadMore
  });
  return _jsxs(React.Fragment, {
    children: [homeFeedData.map((result: HomeFeedResultInterface, index: number) => {
      const componentName = result?.content[0]?.component?.name;
      const componentKey = `${componentName}__${index}`;
      const props = {
        result,
        componentKey,
        key: componentKey,
        sectionBeforeHighlighted
      };
      // console.log({ index: index + 1 })
      switch (componentName) {
        case FEEDCOMPONENTTYPE.FULL_WIDTH_VOD_OR_LIVE:
        case FEEDCOMPONENTTYPE.MEDIUM_WIDTH_VOD:
          // Video Component
          return _jsx(HomePageVideosSection, {
            ...props,
            v_position: index + 1
          });
        case FEEDCOMPONENTTYPE.STREAMERS:
          // Stream Component
          return _jsx(HomePageStreamersSection, {
            ...props,
            v_position: index + 1
          });
        case FEEDCOMPONENTTYPE.CATEGORY_FULL_WIDTH:
          // Category Component
          return _jsx(HomePageCategorySection, {
            ...props,
            v_position: index + 1
          });
        case FEEDCOMPONENTTYPE.ANNOUNCEMENT_BIG:
        case FEEDCOMPONENTTYPE.ANNOUNCEMENT_SMALL:
          return _jsx(HomePageBannerSection, {
            ...props,
            v_position: index + 1
          });
        default:
          return null;
      }
    }), (loading || hasNext) && _jsx("div", {
      ref: moreRef,
      css: _ref,
      children: _jsx(Loader, {})
    })]
  });
};
export default HomeFeedComponent;